<template>
  <div>
    <div class="d-flex justify-end" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'" flat tile>
      <div style="width: 80%"></div>
      <v-select class="pa-2" outlined filled dense v-model="dateRange" @input="dateRange => dateRangeChanged(dateRange)"
        :items="['This Month', 'Last Month', 'Today', 'Yesterday', 'This Year', 'Last Year', 'Custom Date']"></v-select>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
        transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="date" label="Custom Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
            v-on="on"></v-text-field>
        </template>
        <v-date-picker range v-model="date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="dateRangeChanged(date, 'customDate')"> OK </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-card>
      <v-card-title>
        {{ translate('Checkins') }}
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table sort-by.sync="status" sort-desc.sync="true" :items-per-page="20" :search="search" :headers="headers"
        :items="getProducts" class="elevation-1">
        <template v-slot:item.status="{ item }">
          <span class="font-weight-medium v-chip v-size--small" :class="item.color"><span class="v-chip__content"> {{
            item.status }} </span></span>
        </template>
        <template v-slot:item.order_created_at="{ item }">
          <span>{{ formatDate(item.order_created_at) }}</span>&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"><span class="v-chip__content"> {{
            timeAgo(item.order_created_at) }} </span></span>
        </template>
        <template v-slot:item.id="{ item }">
          <v-dialog width="50%">
            <template v-slot:activator="{ on, attrs }">
              <a color="red lighten-2" dark v-bind="attrs" v-on="on">
                {{ item.id }}
              </a>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">{{ translate('Checkin') }} #{{ item.id }}</v-card-title>
              <v-card-text>
                <CheckinInfo @setCheckinData="setCheckinData" :currency="currency" :order="item"></CheckinInfo>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.date="{ item }">
          <span>{{ formatDate(item.date) }}</span>&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"><span class="v-chip__content"> {{
            timeAgo(item.date) }} </span></span>
        </template>
        <template v-slot:item.actions="{ item }">
          <router-link :to="{ name: 'edit_order', params: { id: item.id } }">
            <!--
              <v-icon style="color: var(--v-warning-base)" small> mdi-pencil </v-icon>
            -->
          </router-link>
          <v-dialog :retain-focus="false" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click.stop="item.delete_dialog = true" small style="color: var(--v-error-base)" v-bind="attrs"
                v-on="on">
                mdi-delete
              </v-icon>
            </template>

            <v-card>
              <v-card-title class="text-h5">{{ translate('Are you sure want to delete Checkin') }} {{ item.id
              }}?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="delete_dialog = false">{{ translate('Cancel') }}</v-btn>
                <v-btn color="primary" @click="handleDelete(item.id)">{{ translate('OK') }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
    <div class="text-center">
      <downloadExcel class="elevation-0 v-btn v-size--small v-btn--outlined v-btn--tile" :data="getProducts" :fields="{
        id: 'id',
        date: 'date',
        magazine_id: 'magazine_id',
        magazine_name: 'magazine_name',
        supplier_id: 'supplier_id',
        supplier_name: 'supplier_name',
        supplier_phone_number: 'supplier_phone_number',
        status: 'status',
        total_paid: 'total_paid',
      }">
          Export Excel   
      </downloadExcel>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import OrderInfo from '@/views/pages/orders/OrderInfo.vue'
import CheckinInfo from './CheckinInfo.vue'
import { deleteOrder, getCheckins, getCurrency } from '@/ApiManager'
export default {
  computed: {
    getProducts() {
      return this.products
    },
  },
  setup() {
    const edit_created_date = ref('')
    const edit_customer_name = ref('')
    const edit_customer_status = ref('')
    const edit_magazine = ref('')
    const edit_customer_total_price = ref('')

    return {
      edit_created_date,
      edit_customer_name,
      edit_customer_status,
      edit_magazine,
      edit_customer_total_price,
    }
  },
  data() {
    return {
      search: '',
      dialog: false,
      delete_dialog: false,
      currency: '',
      magazine_names: [],
      headers: [
        {
          align: 'start',
          value: 'name',
        },
        { text: 'ID', value: 'id' },
        { text: this.translate('Created Date'), value: 'date' },
        { text: this.translate('Supplier Name'), value: 'supplier_name' },
        { text: this.translate('Magazine'), value: 'magazine_name' },
        { text: this.translate('Payment Status'), value: 'status' },
        { text: this.translate('Total Payment'), value: 'total_paid' },
        { text: this.translate('Paid'), value: 'actions' },
      ],
      products: [],
      dateRange: 'This Month',
      date: null,
      menu: null,
    }
  },
  components: {
    OrderInfo,
    CheckinInfo,
  },
  methods: {
    getCheckinsLocal() {
      getCheckins(this.dateRange).then(response => {
        this.products = response
        this.products.forEach(
          product => (
            (product.total_paid = `${product.total_paid} ${this.currency}`),
            (product.color = product.supplier_paid == 1 ? 'success' : 'error'),
            (product.status = product.supplier_paid == 1 ? this.translate('Paid') : this.translate('Not Paid')),
            (this.$store.state.loading = false)
          ),
        )
        this.$store.state.loading = false
      })
    },
    dateRangeChanged(dateRange, customDate) {
      if (dateRange == 'Custom Date' && customDate == undefined) return
      this.$store.state.loading = true
      console.log(customDate)
      if (customDate != undefined) {
        this.dateRange = 'Custom Date'
      }
      getCheckins(dateRange).then(response => {
        this.products = response
        this.$store.state.loading = false
      })
    },
    async setCheckinData() {
      this.dialog = false
      await this.getCheckinsLocal()
      this.$store.state.loading = false
    },
    magazine_selected(magazine_name) {
      for (var i = 0; i < this.magazine_names.length; i++)
        if (this.magazine_names[i].name == magazine_name) this.edit_magazine = this.magazine_names[i].id
      console.log(this.edit_magazine)
    },
    handleDelete(id) {
      deleteOrder(id)
      getCurrency().then(response => {
        this.currency = response['currency']
      })
      this.delete_dialog = false
      this.products = this.products.filter(function (item) {
        return item.id != id
      })
    },
  },
  created() {
    this.$store.state.loading = true
    getCurrency().then(response => {
      this.currency = response['currency']
    })
    this.getCheckinsLocal()
  },
}
</script>

<style>
a {
  text-decoration: none;
}
</style>
